import {sendRequest} from "../common/sendRequest"
import { t } from "../common/content"
import { API_HOST } from "../constants";

export async function getBills(params) {
    let url = `${API_HOST}/api/v1/bills`
    return sendRequest({
            url,
            method: 'GET',
            queryParams: params,
            // headers: {'fw-api':'pricing'}
        })
}
