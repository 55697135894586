export const translations = {
    'srb': {
        'Dom' : 'Дом',
        'Bills' : 'Рачуни',
        'Home' : 'Почетна',
        '404 - Page not found' : '404 - Страна није пронађена',
        "403 - You don't have permission to view this page" : "403 - Немате дозволу за приступ овој страни",
        'Invalid credentials' : 'Погрешни креденцијали',
        'Login' : 'Улогуј се',
        'Logout' : 'Излогуј се',
        'Paid' : 'Плаћено',
        'Not paid' : 'Није плаћено',
        'Password' : 'Лозинка',
        'Account' : 'Налог',
        'Email' : 'Имејл адреса',
        'Failed to fetch' : 'Сервис није доступан',
        'English' : 'Енглески',
        'Serbian' : 'Српски',
    },
}